<template>
  <v-app>
    <v-app-bar
        app
        color="#fb7299"
        dark
    >
      <v-app-bar-title style="cursor: pointer" @click="refresh">LOIBUS</v-app-bar-title>
      <v-spacer></v-spacer>

      <v-btn
          href="https://loibus.in"
          target="_blank"
          text
      >
        <span class="mr-2">訪問LOIBUS</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>

      <v-container v-if="isLogin" class="mt-6">
        <template v-if="ad_list.length > 0">
          <v-row class="justify-space-between px-3 justify-md-start">
            <h2 class="mb-5 mr-5">我的推廣({{ ad_list.length }})</h2>
            <v-btn color="#00AEEC" class="white--text" @click="pay_dialog = true">
              <v-icon small style="position: relative;top:1px;" class="mr-1">mdi-currency-usd</v-icon>
              續費支付
            </v-btn>
          </v-row>
          <v-row>
            <v-col sm="6" md="6" lg="3" cols="12" v-for="ad in ad_list" :key="ad.id">
              <v-card>
                <v-img
                    class="white--text align-end"
                    :src="ad.img"
                >
                  <v-card-title>{{ ad.title }}</v-card-title>
                </v-img>

                <!--              <v-card-subtitle class="pb-0">-->
                <!--                到期時間：{{ ad.expired_date }}-->
                <!--              </v-card-subtitle>-->

                <v-card-text class="text--primary">
                  <!--                <div class="subtitle-1">{{ ad.description }}</div>-->
                  <div class="subtitle-1">{{ ad.url }}</div>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      color="#fb7299"
                      text
                      @click="edit(ad)"
                  >
                    編輯
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </template>

        <template v-else>
          <div class="d-flex justify-center mt-10">
            <v-progress-circular
                :size="50"
                color="#fb7299"
                indeterminate

            ></v-progress-circular>
          </div>
        </template>
      </v-container>

      <v-container v-else>
        <v-card class="mx-auto mt-5" width="400">
          <v-card-title>Login</v-card-title>
          <v-card-text>
            <v-text-field label="密鑰" v-model="ad_pass"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn block text color="#fb7299" @click="fetch_sp_list(ad_pass)" :loading="login_loading">登錄</v-btn>
          </v-card-actions>
        </v-card>
      </v-container>


      <v-dialog v-model="edit_dialog" width="500">
        <v-card>
          <v-card-title>編輯
          </v-card-title>
          <v-card-text>
            <v-form v-model="valid" ref="form" lazy-validation>
              <v-text-field
                  v-model="ad_form.ad_title"
                  label="標題"
                  disabled
              ></v-text-field>
              <v-text-field
                  label="描述"
                  v-model="ad_form.ad_description"
                  disabled
              ></v-text-field>
              <v-text-field
                  label="鏈接"
                  v-model="ad_form.ad_url"
                  clearable
                  autofocus
                  color="#00AEEC"
                  :rules="urlRules"
                  required
              ></v-text-field>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="edit_dialog = false">取消</v-btn>
            <v-btn text color="#fb7299" @click="handle_edit" :loading="edit_loading" :disabled="editDisabled">更新
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <v-dialog v-model="pay_dialog" width="500">
        <v-card>

          <v-card-title>USDT(TRC20)</v-card-title>
          <v-card-text>

            <v-img width="200" class="mx-auto mt-7" src="https://static.loibus.vip/images/payments/usdt.jpg"></v-img>

            <v-chip class="mt-11" label>充幣地址：</v-chip>
            <v-text-field value="TXg3Ei8Z3uA1S2hsp5UDNuL8WTAex1PHe2"></v-text-field>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="pay_dialog = false">關閉</v-btn>
            <v-btn color="#fb7299" text @click="renewNotification" :loading="check_loading">
              我已支付，發起續期確認
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie'

export default {
  name: 'App',
  data: () => ({
    edit_dialog: false,
    isLogin: false,
    ad_list: [],
    ad_pass: '',
    login_loading: false,
    edit_loading: false,
    check_loading: false,

    sourceUrl: '',
    editDisabled: true,

    pay_dialog: false,

    ad_form: {
      ad_id: null,
      ad_title: '',
      ad_description: '',
      ad_img: '',
      ad_url: ''
    },

    valid: false,

    urlRules: [
      v => !!v || '鏈接是必須的',
      v => /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(v) || '無效鏈接',
    ],
  }),
  methods: {
    fetch_sp_list(pass) {
      this.login_loading = true
      this.ad_list = [];
      this.sourceUrl = '';


      axios.post('https://api.umiacg.com/api/manage_sp_list', {
        ad_from: pass,
      })
          .then((response) => {

            const res = response.data
            console.log(res);

            if (res.code === 20000) {
              // this.$toast.success("登錄成功！");
              Cookies.set('ad_pass', this.ad_pass)

              this.ad_list = res.data

              this.isLogin = true
            } else {
              this.$toast.error("獲取失敗");
            }

            this.login_loading = false
          })
          .catch((error) => {
            console.log(error);
            this.login_loading = false
          });

    },
    edit(ad) {
      this.sourceUrl = ad.url
      this.editDisabled = true

      this.ad_form = {
        ad_id: ad.id,
        ad_title: ad.title,
        ad_description: ad.description,
        ad_img: ad.img,
        ad_url: ad.url
      }

      this.edit_dialog = true
    },
    handle_edit() {
      if (this.$refs.form.validate()) {
        this.edit_loading = true

        axios.post('https://api.umiacg.com/api/update_sp', {
          ad_id: this.ad_form.ad_id,
          ad_url: this.ad_form.ad_url,
          old_url: this.sourceUrl
        })
            .then((response) => {
              const res = response.data
              console.log(res);
              if (res.code === 20000) {
                this.refresh();

                this.$toast.success("更新成功！");
              } else {
                this.$toast.error("更新失敗");
              }

              this.edit_loading = false
              this.edit_dialog = false
            })
            .catch((error) => {
              console.log(error);
              this.edit_loading = false
            });
      } else {
        this.$toast.error("無效鏈接");
      }

    },
    refresh() {
      const ad_pass = Cookies.get('ad_pass')

      if (ad_pass) {
        this.ad_pass = ad_pass
        this.fetch_sp_list(ad_pass)
      } else {
        this.isLogin = false
      }
    },
    renewNotification() {
      this.check_loading = true
      axios.post('https://api.umiacg.com/api/send_renew_feedback', {
        sponsor: this.ad_list[0].id + '-' + this.ad_list[1].id,
      })
          .then((response) => {
            console.log(response);
            this.$toast.success("申請已送出！");
            this.check_loading = false
            this.pay_dialog = false
          })

          .catch((error) => {
            console.log(error);
            this.check_loading = false
          });

    }
  },
  created() {
    this.refresh()
  },
  watch: {
    'ad_form.ad_url': {
      immediate: true,
      handler(val) {
        this.editDisabled = val === this.sourceUrl;
      }
    }
  }
};
</script>
