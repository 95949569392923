import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const options = {
    position: "bottom-center",
    timeout: 1500,
};

Vue.use(Toast, options);

Vue.config.productionTip = false

new Vue({
    vuetify,
    render: h => h(App)
}).$mount('#app')
